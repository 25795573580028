import { useAssets } from "@/contexts/assets/assets.hook";
import { isProd } from "@/utils";
import {
  getRobustness,
  selectManifestUrl,
  fetchAndPrepareM3U8,
  fetchAndPrepareMPD,
  getDrmConfig
} from "@/utils/player";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
// @ts-expect-error library has no types
import shaka from "shaka-player/dist/shaka-player.ui";
import { usePlayer } from "@/contexts/player/player.hook";
import { getAccessToken } from "@/lib/network";
import { ShakaPlayerProps, DRM_SYSTEM } from "./ShakaPlayer.interface";
import classNames from "classnames";
import styles from "./ShakaPlayer.module.scss";

const ShakaPlayer: FC<ShakaPlayerProps> = ({
  className,
  autoPlay,
  showUI,
  loop,
  muted,
  pdfControls,
  config,
  onLoad,
  assetId,
  startTime = 0,
  url
}) => {
  const videoContainerRef = useRef(null);
  const playerRef = useRef<HTMLVideoElement>(null);
  const { joinAssetChannel, assetChannel } = useAssets();
  const { playerCapabilities, setPlayerRef } = usePlayer();

  const [ready, setReady] = useState(false);
  const [player, setPlayer] = useState<shaka.Player | null>(null);
  const [playerConfig, setPlayerConfig] = useState(null);
  const [, setPlayerUI] = useState<shaka.ui.Overlay | null>(null);
  const [, setDrmError] = useState(null);

  const useHLS = useMemo(
    () => playerCapabilities.drm === DRM_SYSTEM.FAIRPLAY,
    [playerCapabilities.drm]
  );

  const manifestUrl = useMemo(() => {
    const manifest = selectManifestUrl(config, playerCapabilities.drm);
    return useHLS ? manifest.hls : manifest.dash;
  }, [
    config?.dashManifestUrl,
    config?.hlsManifestUrl,
    config?.playreadyManifestUrl,
    playerCapabilities.drm
  ]);

  const initApp = () => {
    // Install built-in polyfills to patch browser incompatibilities.
    shaka.polyfill.installAll();

    // Check to see if the browser supports the basic APIs Shaka needs.
    if (shaka.Player.isBrowserSupported()) {
      // Everything looks good!
      initPlayer();
      getConfiguration();
    } else {
      // This browser does not have the minimum set of APIs we need.
      if (!isProd) {
        console.error("Browser not supported!");
      }
    }
  };

  // Helper functions & Event handlers
  const initPlayer = async () => {
    const player = new shaka.Player();
    await player.attach(playerRef.current);

    player
      .getNetworkingEngine()
      .registerRequestFilter(function (type, request) {
        const token = getAccessToken();

        if ((type === 0 || type === 1) && token) {
          const authHeaderValue = `Bearer ${token}`;
          request.headers["Authorization"] = authHeaderValue;
        }
      });

    setPlayer(player);

    if (showUI) {
      const ui = new shaka.ui.Overlay(player, playerRef.current);
      setPlayerUI(ui);
    }
  };

  const getConfiguration = async () => {
    const robustness = await getRobustness();
    const config = await getDrmConfig(robustness);
    setPlayerConfig(config);
  };

  const loadManifest = async () => {
    try {
      player.configure(playerConfig);
      player.getNetworkingEngine().registerRequestFilter((type, request) => {
        if (request.uris[0].startsWith("blob:")) {
          request.uris[0] = decodeURIComponent(request.uris[0]);
        }
      });

      const masterBlobUrl =
        url ||
        (useHLS
          ? await fetchAndPrepareM3U8(manifestUrl)
          : await fetchAndPrepareMPD(manifestUrl));

      await player.load(masterBlobUrl, startTime);
      onPlayerLoaded(masterBlobUrl);
    } catch (err) {
      if (!isProd) {
        console.error("Error while loading the ShakaPlayer:", err);
      }
      setDrmError(err);
    }
  };

  const onPlayerLoaded = (masterBlobUrl) => {
    const variantTracks = player.getVariantTracks();
    const desiredTrack = variantTracks.reduce((maxTrack, currentTrack) => {
      return currentTrack.height > (maxTrack?.height || 0)
        ? currentTrack
        : maxTrack;
    }, null);

    if (desiredTrack) {
      player.selectVariantTrack(desiredTrack, true);
    }

    if (!autoPlay) {
      playerRef.current.pause();
    }

    if (autoPlay) {
      playerRef.current.play();
    }

    if (!assetChannel.current) {
      joinAssetChannel();
    }

    if (onLoad && playerRef.current) {
      const handleLoadedData = () => {
        onLoad({
          dimensions: {
            width: playerRef.current.videoWidth,
            height: playerRef.current.videoHeight
          },
          duration: playerRef.current.duration * 25,
          url: masterBlobUrl
        });
      };
      playerRef.current.addEventListener("loadeddata", handleLoadedData);
      setPlayerRef(playerRef.current);

      return () => {
        if (playerRef.current) {
          playerRef.current.removeEventListener("loadeddata", handleLoadedData);
          setPlayerRef(null);
        }
      };
    }
  };

  // Initialize the player
  useEffect(() => {
    initApp();
  }, []);

  // Configure the player
  useEffect(() => {
    if (player && playerConfig) {
      player.configure(playerConfig);
      setReady(true);
    }
  }, [player, playerConfig]);

  // Load the manifest
  useEffect(() => {
    if (ready && player && (manifestUrl || url)) {
      shaka.Player.probeSupport().then(() => {
        loadManifest();
      });
    }
  }, [ready, player, manifestUrl, url]);

  useEffect(() => {
    if (!assetChannel.current && assetId) {
      joinAssetChannel(assetId);
    }
  }, [ready]);

  return (
    <div
      ref={videoContainerRef}
      className={classNames(styles.shakaPlayer, {
        pdfControls: pdfControls
      })}
    >
      <video
        ref={playerRef}
        className={classNames(className)}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
      />
    </div>
  );
};

export default ShakaPlayer;
