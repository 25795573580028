import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./FullscreenAnalogPreview.module.scss";
import { getUserProfileImage } from "@/lib/user";
import { getUserInfo } from "@/services/users-service";
import {
  FullscreenAnalogPreviewProps,
  PlayerSizeState
} from "./FullscreenAnalogPreview.types";
import { useLocation } from "react-router-dom";
import {
  getAssetByOwner,
  getAssetInformation,
  getManifests
} from "@/services/asset-service";
import { classNames } from "primereact/utils";
import useResponsive from "@/hooks/useResponsive";
import {
  AdjustPlayerSizeParams,
  BackgroundMode
} from "../fullscreen-card-preview/FullscreenCardPreview.types";
import { AnimatePresence, motion } from "framer-motion";
import ShakaPlayer from "@/components/shared/ShakaPlayer/ShakaPlayer.component";
import {
  IStreamConfiguration,
  OnLoadParams
} from "@/components/shared/ShakaPlayer/ShakaPlayer.interface";

import { ToastTypes } from "@/components/shared/super-toast/SuperToast.types";
import { useSuperToast } from "@/components/shared/super-toast/SuperToast.hook";
import {
  documentMimeTypes,
  getAssetPathFromPath,
  gifMimeTypes,
  pdfMimeTypes,
  videoMimeTypes
} from "@/lib/files";
import debounce from "@/lib/debounce";
import { usePreviewModal } from "../../preview-modal/PreviewModal.hook";
import ActionsBar from "../actions-bar/ActionsBar.component";
import { usePlayer } from "@/contexts/player/player.hook";
import SuperPayPrompt from "../../super-pay/SuperPayPrompt.component";
import RequestAccessPrompt from "../../request-access/RequestAccessPrompt.component";
import FullscreenBackground from "../fullscreen-card-preview/partials/FullscreenBackground/FullscreenBackground.component";
import { useAuth } from "@/contexts/auth/auth.hook";
import { ACCESS_TYPE } from "@/types/contexts/share-context";
import { useCurrentScreen } from "@/contexts/screen/screen.hook";
import { SCREEN_TYPES } from "@/types/contexts/screen-context";
import { generateAssetLink } from "@/lib/link";

function FullscreenAnalogPreview({
  ownerName: ownerNameFromProps,
  assetId: assetIDFromProps,
  isPopup
}: FullscreenAnalogPreviewProps) {
  const [user, setUser] = useState(null);
  const [asset, setAsset] = useState(null);
  const [isRestricted, setIsRestricted] = useState(false);
  const [hasNoAccess, setHasNoAccess] = useState(true);
  const [showRequestAccess, setShowRequestAccess] = useState(false);
  const [showOverlayVideo, setShowOverlayVideo] = useState(false);
  const [showPfpOverlay, setShowPfpOverlay] = useState(false);
  const [showAsset, setShowAsset] = useState(false);
  const [showMobileBorder, setShowMobileBorder] = useState<boolean>(false);
  const [playerSize, setPlayerSize] = useState<PlayerSizeState>({
    width: 1000,
    height: 1000
  });
  const [playerConfig, setPlayerConfig] = useState<IStreamConfiguration>(null);
  const [numPlayers, setNumPlayers] = useState(1);

  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [viewMode, setViewMode] = useState<BackgroundMode>(
    BackgroundMode.GALLERY
  );
  const [playerUrl, setPlayerUrl] = useState(null);

  const location = useLocation();
  const { isMobile } = useResponsive();
  const { addToast } = useSuperToast();
  const { closeModal } = usePreviewModal();
  const { playerCapabilities } = usePlayer();
  const { userChannel, user: loggedInUser } = useAuth();
  const { setCurrentScreen, setCurrentScreenProfile } = useCurrentScreen();

  const [isPaid, setIsPaid] = useState(false);

  const pageContainerRef = useRef<HTMLDivElement | null>(null);
  const playerContainerRef = useRef<HTMLDivElement | null>(null);

  const locationArray = location.pathname.split("/");
  const decodedPath = decodeURIComponent(location.pathname);
  const decodeLocationArray = decodedPath.split("/");
  const ownerName = locationArray[1];
  const assetName = decodeLocationArray[locationArray.length - 1];

  const isPDF = pdfMimeTypes.includes(asset?.mime_type);

  const showUI =
    videoMimeTypes.includes(asset?.mime_type) ||
    documentMimeTypes.includes(asset?.mime_type);
  const autoPlay =
    videoMimeTypes.includes(asset?.mime_type) ||
    gifMimeTypes.includes(asset?.mime_type);
  const pdfControls = isPDF || documentMimeTypes.includes(asset?.mime_type);

  const defaultWidthRatio = 0.85;
  const defaultHeightRatio = 0.85;

  const getAsset = useCallback(async () => {
    const _ownerName = ownerNameFromProps ?? ownerName;
    let assetDetails = null,
      error = null,
      statusCode = null;
    // If an id was passed in, we can get the asset directly, otherwise
    //  if we are using a direct link, need to getAssetByOwner
    if (assetIDFromProps) {
      const response = await getAssetInformation(assetIDFromProps);
      assetDetails = response.data;
      statusCode = response.statusCode;
      error = response.error;
    } else {
      const response = await getAssetByOwner(
        _ownerName,
        getAssetPathFromPath(decodedPath)
      );
      assetDetails = response.data;
      statusCode = response.statusCode;
      error = response.error;
    }
    const message = error?.message ?? error;

    if (statusCode === 403) {
      addToast(
        "You have lost access to this file.",
        ToastTypes.PERMISSION_LOCK
      );
      if (isPopup) {
        closeModal();
      } else {
        history.go(0);
      }
    }

    const manifests =
      playerCapabilities.isDeviceSupported &&
      assetDetails?.access?.access_type !== -1
        ? await getManifests(assetDetails?.id)
        : null;

    setAsset(assetDetails);
    setIsRestricted(assetDetails?.visibility === "restricted");

    setHasNoAccess(
      assetDetails?.access?.access_type === -1 &&
        !assetDetails?.access?.accepted_at
    );
    setPlayerConfig(manifests);
    const { data: userInfoResponse } = await getUserInfo(_ownerName);
    setUser(userInfoResponse.data);
    return { assetDetails, message };
  }, [ownerName, assetName, playerCapabilities.isDeviceSupported]);

  useEffect(() => {
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;

    const defaultPlayerSize = {
      width: pageWidth * defaultWidthRatio,
      height: pageHeight * defaultHeightRatio
    };

    if (defaultPlayerSize.width <= 767) {
      setShowMobileBorder(true);
    } else {
      setShowMobileBorder(false);
    }

    setPlayerSize(defaultPlayerSize);
  }, []);

  useEffect(() => {
    getAsset();
  }, [getAsset]);

  useEffect(() => {
    if (asset) {
      window.Echo.channel(`asset.${asset.id}.public`).listen(
        "AssetUpdated",
        async (data) => {
          if (data.visibility) {
            const { message } = await getAsset();
            if (message) {
              if (isPopup) {
                closeModal();
              } else {
                history.go(0);
              }
              addToast(
                "You have lost access to this file.",
                ToastTypes.PERMISSION_LOCK
              );
            }
          }
        }
      );
    }

    return () => {
      if (asset) {
        window.Echo.leave(`asset.${asset.id}.public`);
      }
    };
  }, [asset]);

  useEffect(() => {
    setShowOverlayVideo(
      playerCapabilities.isDeviceSupported ? !hasNoAccess : false
    );
  }, [hasNoAccess, playerCapabilities.isDeviceSupported, isRestricted]);

  useEffect(() => {
    if (hasNoAccess || !playerCapabilities.isDeviceSupported) {
      if (isRestricted) {
        setShowRequestAccess(true);
      }
      if (isMobile) {
        adjustPlayerSize({ width: 600, height: 900 });
      } else {
        adjustPlayerSize(playerSize);
      }
    }
  }, [asset, hasNoAccess, playerCapabilities.isDeviceSupported]);

  const handleResize = useCallback(
    debounce(() => {
      const pageContainer = pageContainerRef.current;
      if (!pageContainer) return;

      adjustPlayerSize(playerSize);
    }, 200),
    [playerSize.width, playerSize.height]
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    userChannel?.listen("AssetUnshared", () => {
      addToast(
        "You have lost access to this file.",
        ToastTypes.PERMISSION_LOCK
      );

      if (isPopup) {
        return closeModal();
      } else {
        getAsset();
      }
    });

    userChannel?.listen("AssetShared", () => {
      return getAsset();
    });
  }, []);

  const handlePlayerLoad = ({ dimensions, duration, url }: OnLoadParams) => {
    if (playerLoaded) return;
    setPlayerLoaded(true);
    adjustPlayerSize(dimensions);
    if (isPDF) {
      const playersNeeded = duration;
      setNumPlayers(playersNeeded);
    }
    setPlayerUrl(url);
  };

  const adjustPlayerSize = ({
    width: assetWidth,
    height: assetHeight
  }: AdjustPlayerSizeParams) => {
    const pageContainer = pageContainerRef.current;
    const pageWidth = pageContainer?.clientWidth;
    const pageHeight = pageContainer?.clientHeight;
    const assetAspectRatio = assetWidth / assetHeight;
    const defaultPlayerSize = {
      width: pageWidth * defaultWidthRatio,
      height: pageHeight * defaultHeightRatio
    };
    const pageAspectRatio = defaultPlayerSize.width / defaultPlayerSize.height;

    const newPlayerSize = {
      width: null,
      height: null
    };

    if (pageAspectRatio > assetAspectRatio) {
      const playerWidth = defaultPlayerSize.height * assetAspectRatio;
      const playerHeight = defaultPlayerSize.height;

      newPlayerSize.width = playerWidth;
      newPlayerSize.height = playerHeight;
    } else {
      const playerWidth = defaultPlayerSize.width;
      const playerHeight = defaultPlayerSize.width / assetAspectRatio;

      newPlayerSize.width = playerWidth;
      newPlayerSize.height = playerHeight;
    }

    setPlayerSize(newPlayerSize);
    if (defaultPlayerSize.width <= 767) {
      setShowMobileBorder(true);
    } else {
      setShowMobileBorder(false);
    }
  };

  const handleFirstOverlayEnd = () => {
    setShowOverlayVideo(false);
    if (!showRequestAccess) {
      setTimeout(() => {
        setShowPfpOverlay(true);
        setTimeout(() => {
          setShowPfpOverlay(false);
          setTimeout(() => {
            setShowAsset(true);
          }, 1000);
        }, 1500);
      }, 300);
    } else {
      setShowAsset(true);
    }
  };

  const getAssetName = () => {
    return asset?.asset?.name ?? asset?.name;
  };

  const getAssetVisibility = () => {
    return asset?.visibility;
  };

  const visibilityClass = classNames({
    [styles.public]: !isRestricted,
    [styles.restricted]: isRestricted
  });

  const playerWrapperClasses = classNames(styles.playerWrapper, {
    [styles.hidden]: hasNoAccess && isRestricted ? !showRequestAccess : false,
    [styles.mobileBorder]: showMobileBorder,
    [styles.shadow]: rotationAngle % 180 === 0,
    [styles.pdf]: isPDF
  });

  const playerClasses = classNames(styles.playerContainer, {
    [styles.hidden]: !showAsset
  });

  const overlayClasses = classNames(styles.overlayBg, {
    [styles.white]: rotationAngle % 180 === 0
  });

  const handleRotate = () => {
    const playerContainers = document.querySelectorAll(
      `.${styles.playerContainer}`
    );
    playerContainers.forEach((playerContainer) => {
      (playerContainer as HTMLElement).style.transform =
        `rotate(${rotationAngle + 90}deg)`;
    });

    setRotationAngle(rotationAngle + 90);
  };

  const renderActionsBar = () => {
    const userAccessType = asset?.access?.access_type;

    if (
      userAccessType === ACCESS_TYPE.Owner ||
      userAccessType === ACCESS_TYPE.Manager
    )
      return (
        <ActionsBar
          user={user}
          assetName={asset?.name}
          onRotate={handleRotate}
          asset={asset}
          isPopup={isPopup}
        />
      );
  };

  const handleAvatarClick = () => {
    if (user) {
      if (isPopup) {
        closeModal();
      }
      setCurrentScreenProfile({
        path: user?.username,
        owner_id: user?.id,
        username: user?.username
      });
      setCurrentScreen(
        user?.id === loggedInUser?.id
          ? SCREEN_TYPES.DASHBOARD_PROFILE
          : SCREEN_TYPES.PROFILE
      );
    }
  };

  const handleCopy = () => {
    const username = user.username;
    const assetName = asset.name;
    const pathName = location.pathname;
    const protocol = window.location.protocol;
    const host = window.location.host;

    const assetLink = generateAssetLink(
      username,
      assetName,
      pathName,
      protocol,
      host,
      !isPopup
    );

    navigator.clipboard.writeText(assetLink);
    addToast("Your link has been copied", ToastTypes.SUCCESS);
  };

  const handleLogoClick = () => {
    if (isPopup) {
      closeModal();
    }
    setCurrentScreen(
      !loggedInUser ? SCREEN_TYPES.LOGIN : SCREEN_TYPES.DASHBOARD_ROOT
    );
  };

  // if (asset?.access?.access_type !== -1) return null;

  return (
    <div className={styles.container} ref={pageContainerRef}>
      <FullscreenBackground
        user={user}
        viewMode={viewMode}
        setViewMode={setViewMode}
        isPopup={isPopup}
      />

      <div className={styles.header}>
        <div className={styles.leftControls}>
          <button
            onClick={handleAvatarClick}
            className={styles.headerAvatarButton}
          >
            <img
              src={getUserProfileImage(user?.profile?.avatar)}
              alt="User Profile"
              className={styles.headerPfp}
            />
          </button>
          {!isMobile && <span>@{user?.username}</span>}
          <span>/</span>
          <span className={styles.assetName} onClick={handleCopy}>
            {getAssetName()}
          </span>
          {!isMobile && (
            <span className={visibilityClass}>[{getAssetVisibility()}]</span>
          )}
        </div>
        <div className={styles.rightControls}>
          {isPopup && !isMobile && <span onClick={closeModal}>Close</span>}

          <div className={styles.headerLogo}>
            <button
              onClick={handleLogoClick}
              className={styles.headerLogoButton}
            >
              <img
                src="/images/logos/superfile-logo-black.svg"
                alt="Superfile logo"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        className={playerWrapperClasses}
        style={isPDF ? { height: "95vh", width: playerSize.width } : playerSize}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={overlayClasses} style={playerSize} />
        <AnimatePresence>
          {showOverlayVideo && (
            <motion.div
              key="overlay1"
              className={styles.overlayContainer}
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transitionEnd: { display: "none" } }}
              transition={{ duration: 0.5 }}
            >
              <video
                className={styles.overlayVideo}
                playsInline
                autoPlay
                muted
                onEnded={handleFirstOverlayEnd}
              >
                <source src="/videos/app-white.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {!playerCapabilities.isDeviceSupported && !hasNoAccess && (
            <div className={styles.unsupportedBrowserText}>
              Viewing SuperFiles in this browser is not currently supported
            </div>
          )}
          {hasNoAccess ? (
            isPaid ? (
              <SuperPayPrompt
                assetPrice={24.95}
                ownerInfo={asset?.owner}
                deviceSupported={playerCapabilities.isDeviceSupported}
                setHasPaid={() => setHasNoAccess(false)}
              />
            ) : isRestricted ? (
              <RequestAccessPrompt assetToRequest={asset} />
            ) : null
          ) : null}

          {showPfpOverlay && (
            <motion.div
              key="overlay2"
              className={styles.overlayContainer}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transitionEnd: { display: "none" } }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <img
                src={getUserProfileImage(user?.profile?.avatar)}
                alt="User Profile"
                className={styles.overlayImage}
              />
            </motion.div>
          )}
        </AnimatePresence>
        {playerConfig && (
          <div className={styles.shakaPageContainer}>
            <motion.div
              key="player"
              ref={playerContainerRef}
              className={playerClasses}
              style={playerSize}
            >
              <ShakaPlayer
                autoPlay={autoPlay}
                showUI={showUI}
                config={playerConfig}
                loop={autoPlay}
                muted
                pdfControls={pdfControls}
                onLoad={handlePlayerLoad}
                startTime={0}
              />
            </motion.div>
          </div>
        )}
        {playerConfig && playerLoaded && numPlayers >= 2 && (
          <div className={styles.shakaPageContainer}>
            {Array.from(
              { length: numPlayers - 1 },
              (_, index) => index + 1
            ).map((index) => (
              <motion.div
                key={`player-${index}`}
                ref={playerContainerRef}
                className={playerClasses}
                style={{
                  top: (playerSize.height + 10) * index,
                  ...playerSize
                }}
              >
                <ShakaPlayer
                  autoPlay={autoPlay}
                  showUI={showUI}
                  config={playerConfig}
                  loop={autoPlay}
                  muted
                  pdfControls={pdfControls}
                  onLoad={handlePlayerLoad}
                  startTime={index / 25}
                  url={playerUrl}
                />
              </motion.div>
            ))}
          </div>
        )}
      </div>

      {renderActionsBar()}
    </div>
  );
}

export default FullscreenAnalogPreview;
